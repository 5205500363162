window.isNull = function(entity){
  return (entity == null || entity == undefined || entity == 'undefined')
}

window.isEmpty = function(entity) {
  if (window.isNull(entity)) {
    return true;
  }

  return entity == "";
}

window.guard = function(entity, _function){
  if (window.isEmpty(entity)) { return; }
  _function();
}