document.addEventListener("DOMContentLoaded", event => {

  const languageSubmitElement = document.getElementById('languages_submit')
  if (languageSubmitElement) {
    languageSubmitElement.addEventListener('click', (event) => {
      const select = document.getElementById('languages_select')
      const value = select.options[select.selectedIndex].value
      document.cookie = "language=" + value + "; path=/";
      window.location = window.location;
    });
  }
});