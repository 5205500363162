import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://d2117f008c65455bad15a64c0beb5f24@sentry.lico.nl/90',
  // beforeSend(event, _hint) {
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //   }
  //   return event;
  // }
});

import '../polyfills/index'

require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")

// import '../stylesheets/application.scss'

import '../components/helper'
import '../components/debug'
import '../chat/introduction'
import '../components/language'
import {Theme} from '../components/theme'
import '../components/carousel'
import '../components/debug'
import '../chat/basket_removed_unavailable'
import '../chat/mollie_payment_failed'
import '../chat/mollie_payment_pending'
import '../models/table'
import '../models/payments'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

document.addEventListener("DOMContentLoaded", function() {
  Theme.init()
});
