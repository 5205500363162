const Payments = (function(){
  'use strict';

  function init(){
    const paymentMethodsElement = document.querySelector('[data-payment-methods]')
    if (paymentMethodsElement) {
      const selectElement = paymentMethodsElement.querySelector('select')
      selectElement.removeEventListener('change', onPaymentMethodChanged)
      selectElement.addEventListener('change', onPaymentMethodChanged);

      handlePaymentMethod(selectElement)
    }
  }

  function onPaymentMethodChanged(event){
    handlePaymentMethod(event.currentTarget)
  }

  function handlePaymentMethod(element){
    const paymentMethod = element.options[element.selectedIndex].value
    const paymentMethodExplanationElements = Array.from(document.querySelectorAll('[data-payment-method]'))

    paymentMethodExplanationElements.forEach(function(paymentMethodExplanationElement){
      if (!paymentMethodExplanationElement.classList.contains('hidden')) {
        paymentMethodExplanationElement.classList.add('hidden')
      }
    })

    const selectedPaymentMethodExplanationElement = document.querySelector('[data-payment-method="' + paymentMethod + '"]')
    if (selectedPaymentMethodExplanationElement.dataset.surcharge != '0') {
      selectedPaymentMethodExplanationElement.classList.remove('hidden')
    }
  }

  return {
    init: init,
  }
})();

document.addEventListener("DOMContentLoaded", function() {
  Payments.init();
});
