window.log_debug = function(text, color) {
  if (window.rails_env == 'development' || window.rails_env == 'staging') {
    console.log('%c' + text, 'color: ' + color);
  }
};

window.log_error = function(text) {
  if (window.rails_env == 'development' || window.rails_env == 'staging') {
    console.error(text);
    debugger
  }
};
