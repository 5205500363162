import Chat from './chat'
import Api from "../models/api";

const parseJson = require('json-parse-better-errors')

const mollie_payment_pending = (function () {
  document.addEventListener("DOMContentLoaded", function () {
    if (!document.getElementById('mollie-pending-payment')) { return; }

    let chat = document.getElementById('mollie-pending-payment').dataset.chat;
    start(parseJson(chat));
  });

  function start(chatData) {
    const chatListElement = document.querySelector('#mollie_payment_pending_chat');
    if (!chatListElement) { return; }
    Chat.startChat(chatListElement, chatData.chat, function () {
      window.setTimeout(wait_for_other_status, 2500);
    });
  };

  function wait_for_other_status() {
    let dataset = document.getElementById('mollie-pending-payment').dataset;
    const request = new Request(dataset.status);
    Api.callApi(request, 'GET').then((result) => {
      if (result.status != 'pending') {
        window.location = dataset.target
      } else {
        window.setTimeout(wait_for_other_status, 2500);
      }
    });
  };
})();

export default mollie_payment_pending;
