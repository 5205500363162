import Chat from './chat'
import ChatBubble from './chatbubble'

const parseJson = require('json-parse-better-errors')

const mollie_payment_failed = (function () {
  document.addEventListener("DOMContentLoaded", function () {
    if (!document.getElementById('finalize-basket')) { return; }

    let chat = document.getElementById('finalize-basket').dataset.chat;
    start(parseJson(chat));
  });

  function start(chatData) {
    const chatListElement = document.querySelector('#mollie_payment_failed_chat');
    if (!chatListElement) { return; }
    Chat.startChat(chatListElement, chatData.chat, function () {
      const link_menu = ChatBubble.createLink(chatData.button_card, '/categories')
      Chat.appendToChat(link_menu, function () {
        const link_orders = ChatBubble.createSubmit(chatData.button_try_again);
        Chat.appendToChat(link_orders, null, 100, 100);
      });
    });
  };
})();

export default mollie_payment_failed;
