import {Notification} from "../components/notification";
import * as Sentry from "@sentry/browser";

const Api = (function(){
  const headers = new Headers();
  headers.append('pragma', 'no-cache'); //No caching!
  headers.append('cache-control', 'no-cache');
  headers.append('Content-Type', 'application/json')

  function callApi(request, method, data) {
    return new Promise((resolve, reject) => {

      const initObject = {
        method: method,
        headers: headers,
        credentials: 'include'
      };

      if (method == "POST" || method == "PATCH" || method == "DELETE") {
        data.authenticity_token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
        initObject.body = JSON.stringify(data) ;
      }

      let status;

      fetch(request, initObject)
        .then(function (response) {
          status = response.status
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
          } else {
            return response.text();
          }
        })
        .then(function (data) {
          if (status < 300) {
            resolve(data);
          } else if (data.error) {
            reject(data); // handle error in caller
          } else {
            if (status !== 404 && status !== 422) { // 422 can be caused by not validating authenticity_token (ie. session expired)
              Sentry.captureException(new Error("Status: " + status + " rejecting data"));
            }
            Notification.createErrorNotification(status);
            reject(data)
          }
        })
        .catch(function (error) {
          Sentry.captureException(error);
          Notification.createErrorNotification(status);

          reject(error)
        });
    });
  }

  return {
    callApi: callApi
  }
})();


export default Api;