(function(){
  document.addEventListener("DOMContentLoaded", function() {
    const carousels = document.querySelectorAll('[data-carousel]');
    Array.from(carousels).forEach((carousel) => {
      handleCarousel(carousel)
    })
  });

  function handleCarousel(element){
    const carousel = element;
    const elementsCount = carousel.querySelectorAll('[data-carousel-item]').length - 1;
    let index = 0;

    const rights = carousel.querySelectorAll('.arrow-right');
    const container = carousel.querySelector('.carousel-items');

    Array.from(rights).forEach(right => {
      right.addEventListener('click', function () {

        if (index == elementsCount) { return;}

        index += 1;
        container.style.transform = 'translateX(' + index * -100 + '%)'

      });
    });
  }
})();