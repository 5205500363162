import logo from '../images/logo.png'

const ChatBubble = (function() {
  const createChattingBubble = function(){
    const bubbleWrapper = document.createElement("div");
    bubbleWrapper.classList.add('chatting');

    const newContent = document.createTextNode('....');
    bubbleWrapper.appendChild(newContent);
    return bubbleWrapper;
  };

  const createChatBubble = function(data) {
    const bubbleWrapper = document.createElement("div");
    bubbleWrapper.classList.add('butler');

    const butler_image = document.createElement("div");
    butler_image.classList.add('img')

    const butler_icon = document.createElement("i");
    butler_icon.classList.add('fa');
    butler_icon.classList.add('fa-user-tie')
    butler_image.appendChild(butler_icon);

    bubbleWrapper.appendChild(butler_image);

    const text = document.createElement("div");
    text.classList.add('text');
    bubbleWrapper.appendChild(text);

    const newContent = document.createTextNode(data.text);
    text.appendChild(newContent);
    return bubbleWrapper;
  };

  const createInput = function(name, type, callback) {
    const bubbleWrapper = document.createElement("form");
    bubbleWrapper.classList.add('you');

    const div = document.createElement("div");
    div.classList.add('text');
    bubbleWrapper.appendChild(div);

    const input = document.createElement("input");
    input.classList.add('input');
    input.setAttribute('type', type)
    input.name = name
    input.setAttribute('step', '0.01')
    div.appendChild(input);
    return bubbleWrapper;
  }

  const createButton = function(text, callback, className = 'is-primary') {
    const bubbleWrapper = document.createElement("div");
    bubbleWrapper.classList.add('you');

    const input = document.createElement("button");
    input.classList.add('button');
    if (className != "") {
      input.classList.add(className);
    }
    input.addEventListener('click', function(event) {
      if (event.currentTarget) {
        event.currentTarget.classList.add('is-primary-dark')
      }
      callback(event);
    });

    bubbleWrapper.appendChild(input);

    const newContent = document.createTextNode(text);
    input.appendChild(newContent);

    return bubbleWrapper;
  }

  const createLink = function(text, href) {
    const bubbleWrapper = document.createElement("div");
    bubbleWrapper.classList.add('you');

    const link = document.createElement("a");
    link.classList.add('button');
    link.classList.add('is-primary');
    link.href = href
    bubbleWrapper.appendChild(link);

    const newContent = document.createTextNode(text);
    link.appendChild(newContent);
    return bubbleWrapper;
  };

  const createSubmit = function(text) {
    const bubbleWrapper = document.createElement("div");
    bubbleWrapper.classList.add('you');

    const link = document.createElement("button");
    link.classList.add('button');
    link.classList.add('is-primary');
    link.type = 'submit'
    bubbleWrapper.appendChild(link);

    const newContent = document.createTextNode(text);
    link.appendChild(newContent);
    return bubbleWrapper;
  };

  return {
    createChattingBubble: createChattingBubble,
    createChatBubble: createChatBubble,
    createInput: createInput,
    createLink: createLink,
    createButton: createButton,
    createSubmit: createSubmit
  }
})();

export default ChatBubble;
