import Chat from './chat'
import ChatBubble from './chatbubble'
import Api from '../models/api'

const introduction = (function() {

  let chatListElement = null;

  window.addEventListener("load", function () {

    let chatDataUrlElement = document.head.querySelector('meta[name="introduction_data_path"]')
    if (!chatDataUrlElement) { return; }

    chatListElement = document.querySelector('#introduction');
    if (!chatListElement) { return; }

    let chatDataUrl = chatDataUrlElement.getAttribute('content')

    if (!getCookie("language")) {
      showSelectLanguage();
      return;
    }

    const request = new Request(chatDataUrl);
    Api.callApi(request, 'GET', )
      .then((result) => {
        start(result);
      }).catch((error) => {
        window.location = '/categories'
    })
  });

  function start(chatData) {

    Chat.startChat(chatListElement, chatData.start, function(){

      let introButtonPressed = false;

      const introductionButton = ChatBubble.createButton(chatData.button_introduction, function(event) {
        if (!introButtonPressed) {
          introButtonPressed = true
          Chat.startChat(chatListElement, chatData.introduction_request_positive, function () {
            const toCardButton = ChatBubble.createLink(chatData.button_card, '/categories');
            Chat.appendToChat(toCardButton);
          });
        }
      });

      const toCardButton = ChatBubble.createLink(chatData.button_card, '/categories');

      Chat.appendToChat(toCardButton, function() {
        Chat.appendToChat(introductionButton, null, 100, 100)
      });
    });
  };

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  function showSelectLanguage() {
    document.getElementById('language').classList.add('show')
  }

})();

export default introduction;
