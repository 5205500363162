const Notification = (function () {
  let lastSuggestionTimer = 0;

  function createNotification(text) {
    const div = document.createElement('div');
    div.classList.add('notice');
    div.classList.add('notice-right')

    const icon = document.createElement('i');
    icon.classList.add('fa')
    icon.classList.add('animate-right')
    icon.classList.add('fa-arrow-right')

    const butlerIcon = document.createElement('i');
    butlerIcon.classList.add('fa')
    butlerIcon.classList.add('fa-user-tie')

    const span = document.createElement('span');
    span.innerText = text;

    div.appendChild(butlerIcon)
    div.appendChild(span)
    div.appendChild(icon)

    document.body.appendChild(div);

    window.getComputedStyle(div).transform
    window.setTimeout(_ => {
      document.body.removeChild(div)
    }, 50000);
  }

  function createToast(text){
    const div = document.createElement('div');
    div.classList.add('notice');
    div.classList.add('notice-bottom-long')
    div.innerText = text;
    document.body.appendChild(div);

    window.getComputedStyle(div).transform
    window.setTimeout(_ => {
      document.body.removeChild(div)
    }, 12000);
  }

  function createSuggestion(suggestion, suggestion_html) {
    const noticeElement = document.getElementById('suggestion_notice');

    const suggestionElement = document.getElementById('suggestion');
    suggestionElement.innerText = suggestion

    const height = calculateHeight(noticeElement, suggestionElement);
    noticeElement.style.top = (window.innerHeight - height)+ 'px';

    const suggestionContentElement = document.getElementById('suggestion-content');
    suggestionContentElement.innerHTML = suggestion_html;

    const expander = document.getElementById('suggestion_notice_expander')

    expander.removeEventListener('click', handleSuggestion)
    expander.addEventListener('click', handleSuggestion)

    lastSuggestionTimer = window.setTimeout(_ => {
      clearSuggestion(noticeElement)
    }, 10000);

    return noticeElement;
  }

  function calculateHeight(noticeElement, suggestionElement) {
    try {
      const style = window.getComputedStyle(suggestionElement, null);
      const height = suggestionElement.offsetHeight;
      const margin = parseFloat(style['marginTop']) + parseFloat(style['marginBottom']);

      const footer = document.querySelector('footer');
      const footerStyle = window.getComputedStyle(footer, null);
      const footerHeight = footer.offsetHeight;
      const footerMargin = parseFloat(footerStyle['marginTop']) + parseFloat(footerStyle['marginBottom']);

      const noticePaddingTop = parseFloat(window.getComputedStyle(noticeElement)['padding'])

      return (height + margin + footerHeight + footerMargin + noticePaddingTop + 25)
    } catch(ex) {
      return 100;
    }
  }

  function handleSuggestion(event) {
    window.clearTimeout(lastSuggestionTimer)
    const noticeElement = document.getElementById('suggestion_notice');

    if (noticeElement.classList.contains('suggestion-notice--active')) {
      clearSuggestion(noticeElement)
    } else {
      document.body.style.overflow = ''
      noticeElement.classList.add('suggestion-notice--active')
      document.body.style.overflow = 'hidden'
      noticeElement.style.top = (16 * 2) + 'px'

      const noticeIcon = noticeElement.querySelector('.fa-arrow-circle-up')
      noticeIcon.classList.remove('fa-arrow-circle-up')
      noticeIcon.classList.add('fa-times')
    }
  }

  function clearSuggestion(noticeElement) {
    noticeElement.style.top = (window.innerHeight + 100) + 'px'
    noticeElement.classList.remove('suggestion-notice--active')

    const suggestionContentElement = document.getElementById('suggestion-content');
    suggestionContentElement.innerHTML = ''
    document.body.style.overflow = ''
    const noticeIcon = noticeElement.querySelector('.fa-times')
    if (noticeIcon) {
      noticeIcon.classList.remove('fa-times')
      noticeIcon.classList.add('fa-arrow-circle-up')
    }
  }

  function createErrorNotification(statusCode) {
    const errorNotificationElement = document.getElementById('error-notification')
    if (errorNotificationElement) {
      if (statusCode == 422 || statusCode == 404){
        errorNotificationElement.querySelector('#session').classList.remove('is-hidden');
      } else {
        errorNotificationElement.querySelector('#error').classList.remove('is-hidden');
      }
      errorNotificationElement.classList.add('is-active')

    } else {
      window.location = '/categories';
    }
  }

  return {
    createNotification: createNotification,
    createToast: createToast,
    createSuggestion: createSuggestion,
    clearSuggestion: clearSuggestion,
    createErrorNotification: createErrorNotification
  }

})();

export {Notification}