import {Notification} from "../components/notification";
import Storage from '../models/storage'

const Theme = (function(){

  'use strict'

  let currentTheme = '';
  let availableThemes = ['light', 'samsung-dark']
  let themesElement = null;
  let themeToastText = '';

  function init() {
    const themeMetaTag = document.head.querySelector('meta[name="theme_text"]')
    if (themeMetaTag) {
      themeToastText = themeMetaTag.getAttribute('content')
    }

    currentTheme = document.body.dataset.theme
    if (Storage.getItem('theme')) {
      currentTheme = Storage.getItem('theme')
    }

    themesElement = document.getElementById('themes')

    resetTheme();

    if (themesElement) {
      themesElement.addEventListener('change', (event) => {
        const select = event.currentTarget
        const value = select.options[select.selectedIndex].value
        setNewTheme(value)
      });
    }

    if (currentTheme == 'light' && !hasShownPopup()) {
      if (navigator.userAgent.match(/SamsungBrowser/i)) {
        Notification.createToast(themeToastText)
      }
    }
  }

  function setNewTheme(theme){
    currentTheme = theme
    Storage.setItem('theme', currentTheme)
    resetTheme()
  }

  function resetTheme() {
    availableThemes.forEach(theme => {
      if(theme != currentTheme) {
        document.body.classList.remove(theme)
      }
    })

    if (!document.body.classList.contains(currentTheme)) {
      document.body.classList.add(currentTheme);
    }

    if (themesElement) {
      themesElement.value = currentTheme
    }
  }

  function hasShownPopup() {
    if (Storage.getItem('shownThemePopup')) { return true; }

    Storage.setItem('shownThemePopup', '1')
    return false
  }

  return {
    init: init
  }
})();

export {Theme}

