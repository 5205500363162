import ChatBubble from './chatbubble'

const Chat = (function() {
  const TYPING_DELAY = window.rails_env == 'test' || window.rails_env == 'development' ? 500 : 800;
  const READ_DELAY = window.rails_env == 'test' || window.rails_env == 'development' ? 500 : 1200;

  let chatData = [];
  let chatIndex = 0;
  let chatTimeout = 0;
  let mainTimeout = 0;
  let transform = 0;

  let callbackRef = null;
  let chatListElement = null;

  const startChat = (element, data, callback) => {
    chatListElement = element;
    callbackRef = callback;
    chatIndex = 0;
    chatData = data;
    chat();
  };

  const chat = function(response, entityId) {
    if (chatData.length === chatIndex) {
      callbackRef();
      return;
    }

    if (chatIndex > chatData.length) {
      log_error('chatIndex over length', 'red')
      Sentry.captureException(new Error("chatIndex bigger then chatData.length (exception handled)"))
      callbackRef();
      return;
    }

    mainTimeout = window.setTimeout(function() {

      const chattingBubble = ChatBubble.createChattingBubble();
      _appendToChat(chattingBubble);

      chatTimeout = window.setTimeout(function() {
        chatListElement.removeChild(chatListElement.lastChild);
        const data = chatData[chatIndex]
        if (data == null || data == undefined || data == 'undefined') {
          // do nothing
        } else {
          const newBubble = ChatBubble.createChatBubble(chatData[chatIndex]);
          _appendToChat(newBubble);
        }
        chatIndex += 1;
        chat();
      }, TYPING_DELAY);
    }, READ_DELAY)
  };

  const appendToChat = function(element, callback, typingDelay = TYPING_DELAY, readDelay = READ_DELAY ) {
    mainTimeout = window.setTimeout(function() {
      const chattingBubble = ChatBubble.createChattingBubble();
      _appendToChat(chattingBubble);

      chatTimeout = window.setTimeout(function() {
        chatListElement.removeChild(chatListElement.lastChild);
        _appendToChat(element);
        if (callback != undefined) {
          callback();
        }
      }, typingDelay);
    }, readDelay)
  };

  const appendCloneToChat = function(element, callback, typingDelay = TYPING_DELAY, readDelay = READ_DELAY ) {
    mainTimeout = window.setTimeout(function() {
      const chattingBubble = ChatBubble.createChattingBubble();
      _appendToChat(chattingBubble);

      chatTimeout = window.setTimeout(function() {
        chatListElement.removeChild(chatListElement.lastChild);
        chatListElement.appendChild(element);
        const appendedElement = chatListElement.lastElementChild;

        const style = window.getComputedStyle(appendedElement, null); //flush
        const height = style.getPropertyValue("height");
        const margin = parseFloat(style['marginTop']) + parseFloat(style['marginBottom']);

        transform -= (parseInt(height.match(/\d+/)[0]) - margin);

        chatListElement.style.transform = 'translateY(' + transform + 'px)';
        if (callback != undefined) {
          callback();
        }
      }, typingDelay);
    }, readDelay)
  };

  const _appendToChat = function(element) {
    chatListElement.appendChild(element);
    const style = window.getComputedStyle(element, null);
    const height = style.getPropertyValue("height");
    const margin = parseFloat(style['marginTop']) + parseFloat(style['marginBottom']);

    transform -= (parseInt(height.match(/\d+/)[0]) - margin);

    chatListElement.style.transform = 'translateY(' + transform + 'px)';
  };

  const resetChat = function() {
    clearTimeout(mainTimeout);
    clearTimeout(chatTimeout);
    transform = 0;
    chatListElement.style.transform = 'translateY(' + transform + ')';

    if (chatListElement != undefined) {
      while (chatListElement.childElementCount > 1) {
        chatListElement.removeChild(chatListElement.lastChild);
      }
    }
  };

  return {
    startChat: startChat,
    resetChat: resetChat,
    appendToChat: appendToChat,
    appendCloneToChat: appendCloneToChat
  }
})();

export default Chat
