import Api from './api'
import {cloner} from "../components/clone";
import Chat from "../chat/chat";
import ChatBubble from "../chat/chatbubble";

const introduction = (function() {
  let assignTableUrl = null;
  let confirmLocationUrl = null;
  let formElement = null;
  let chatListElement = null;

  document.addEventListener("DOMContentLoaded", function () {
    init();
  });

  function init() {
    let assignTableUrlElement = document.head.querySelector('meta[name="assign_table_path"]')
    let confirmLocationUrlElement = document.head.querySelector('meta[name="confirm_registration_path"]')
    let chatDataUrlElement = document.head.querySelector('meta[name="registration_data_path"]')

    if (!assignTableUrlElement || !confirmLocationUrlElement) {
      return;
    }

    assignTableUrl = assignTableUrlElement.getAttribute('content');
    confirmLocationUrl = confirmLocationUrlElement.getAttribute('content');
    formElement = document.getElementById('confirm_registration_form');
    chatListElement = document.querySelector('#registration');

    if (!formElement || !chatListElement) {
      return
    }

    const request = new Request(chatDataUrlElement.getAttribute('content'));
    Api.callApi(request, 'GET',)
      .then((result) => {
        start(result);
      }).catch((error) => {
        window.location = '/categories'
    })

    function start(chatData) {
      Chat.startChat(chatListElement, chatData.start, function(){
        showInput(chatListElement, chatData);
      });
    }

    function showInput(chatListElement, data) {
      const template = document.getElementById('register_input');
      const clone = cloner.createElementFromTemplate({}, template);

      Chat.appendCloneToChat(clone, function () {
        const templateUser = document.getElementById('registered_user');
        const cloneUser = cloner.createElementFromTemplate({}, templateUser);
        document.getElementById('users').appendChild(cloneUser);

        showButtons(chatListElement, data, true);
      });
    }

    function showButtons(chatListElement, data, initial = false) {
      const buttonAddUser = ChatBubble.createButton(data.button_add_user, function(event){
        let validationData = validateInput()
        if (validationData.hasPhoneNumber && validationData.allHaveName) {
          Chat.startChat(chatListElement, data.other_user, function () {
            showInput(chatListElement, data);
          });
        }

        event.preventDefault();
        event.stopPropagation();
      });

      const buttonSubmit = ChatBubble.createButton(data.button_to_card, function(event){
        let validationData = validateInput(false)
        if (validationData.hasInput && !validationData.hasPhoneNumber) {
          validateInput();
          event.preventDefault();
          event.stopPropagation();
        } else {
          formElement.submit();
        }
      });

      Chat.appendToChat(buttonAddUser, function() {
        Chat.appendToChat(buttonSubmit, () => {
          // Chat.appendToChat(buttonHelp, null,100, 100);
        },100, 100)
      }, 100, 100);
    }

    function validateInput(showErrors = true) {
      let hasPhoneNumber = false;
      let allHaveName = true;
      let hasInput = false;

      const inputElements = Array.from(formElement.querySelectorAll('input'))

      inputElements.forEach(element => {
        element.classList.remove('field_with_errors')

        if (element.name == 'guests[][phone]' ) {
          if (!window.isEmpty(element.value)) {
            hasInput = true
            hasPhoneNumber = true;
          } else if (!hasPhoneNumber && showErrors) {
            element.classList.add('field_with_errors')
          }
        } else if (element.name == 'guests[][name]') {
          if (window.isEmpty(element.value)) {
            allHaveName = false
            if (showErrors) {
              element.classList.add('field_with_errors')
            }
          } else {
            hasInput = true
          }
        }
      })

      return {
        hasPhoneNumber: hasPhoneNumber,
        allHaveName: allHaveName,
        hasInput: hasInput
      }
    }

  }
})();
